.homepage-grid {
  background: #de6161; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2657eb,
    #de6161
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2657eb,
    #de6161
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
}

.header-color {
  background: #de6161; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2657eb,
    #de6161
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2657eb,
    #de6161
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.profile-img-border {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #ddd;
  margin-left: auto;
  margin-right: auto;
}

.profile-img-border img {
  width: 100%;
  height: auto;
  margin-top: -25px;
}

.banner-text {
  border-radius: 10px;
  background-color: black;
  opacity: 0.8;
  width: 75%;
  margin: auto;
}

.banner-text h1 {
  font-size: 66px;
  font-weight: bold;
  color: #fff;
}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.banner-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
}

.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.social-links i {
  color: white;
  padding: 8px;
  font-size: 5em;
}

.projects-grid {
  margin: auto;
  width: 100%;
}

.project-item {
  float: left;
  margin: 50px 55px 0px 50px;
}

.contact-body {
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;
}

.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  background: white;
  color: black;
  margin-bottom: 20px;
}

.contact-grid h2 {
  font-family: "Anton", sans-serif;
}

.contact-grid p {
  font-family: "Oxygen", sans-serif;
  font-weight: bold;
}

.contact-grid hr {
  border-top: 5px dotted black;
  margin: auto;
  width: 50%;
}

.contact-list i {
  font-size: 50px;
  margin-right: 5rem;
}

.resume.gird {
  padding-top: 0px;
}

.resume-right-col {
  background: #27221f;
  padding: 0px 20px;
  padding-top: 0px;
  margin-top: 0px;
  color: #fff;
}
